
.fade-enter-active[data-v-aa5658a8],
.fade-leave-active[data-v-aa5658a8] {
  transition: opacity 0.5s;
}
.fade-enter[data-v-aa5658a8],
.fade-leave-to[data-v-aa5658a8] {
  opacity: 0;
}
.rc-modal[data-v-aa5658a8] {
  width: 98vw;
}
@media (min-width: 768px) {
.rc-modal[data-v-aa5658a8] {
    width: 800px;
}
}
