
.rc-img-tall[data-v-6e5ab71f], .rc-img-standard[data-v-6e5ab71f] {
  height: 200px;
}
.rc-img-square[data-v-6e5ab71f] {
    width: 300px;
    height: 300px;
}
.rc-img-portrait[data-v-6e5ab71f] {
    width: 200px;
    height: 275px;
}
.rc-img-landscape[data-v-6e5ab71f] {
    width: 320px;
    height: 200px;
}
@media (min-width: 1024px) {
.rc-img-standard[data-v-6e5ab71f] {
    height: 250px;
}
.rc-img-tall[data-v-6e5ab71f] {
    height: 500px;
}
.rc-img-square[data-v-6e5ab71f] {
    width: 250px;
    height: 250px;
}
.rc-img-portrait[data-v-6e5ab71f] {
    width: 250px;
    height: 333px;
}
.rc-img-landscape[data-v-6e5ab71f] {
    width: 444px;
    height: 250px;
}
}
