
.rc-menu[data-v-0cde8e64] {
  content: '';
  width: 0;
  height: 0;
  top: -50px;
  right: -50px;
  border-top: 0;
  border: 50px solid transparent;
  border-bottom: 50px solid white;
}
